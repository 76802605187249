import { Col, Container, Row } from "react-bootstrap"
import { Layout, NewsCard, Pagination } from "../components"
import { Link, withI18next } from "gatsby-plugin-i18next"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { I18n } from "react-i18next"
import React from "react"
import SEO from "../components/seo"
import { faNewspaper } from "@fortawesome/free-solid-svg-icons"
import { graphql } from "gatsby"

const NewsList = ({ data }) => {
  const { pageInfo } = data.allContentfulNews
  return (
    <I18n>
      {t => (
        <Layout>
          <SEO title={t("medee")} />
          <div className="section bg-dark-blue mt-4">
            <Container className="height-md-30">
              <Row>
                <Col md="8" lg="7" xl="6">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb p-0 bg-dark-blue bg-transparent">
                      <li className="breadcrumb-item">
                        <Link to="/" className="text-white">
                          {t("home")}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/news" className="text-white">
                          {t("news")}
                        </Link>
                      </li>
                    </ol>
                  </nav>
                </Col>
                <Col md="12">
                  <h1 className="display-5 text-white">
                    {t("news")} <FontAwesomeIcon icon={faNewspaper} />
                  </h1>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="content news-wrapper">
            <Container>
              <Row>
                <Col>
                  <h2>{t("latest_news")}</h2>
                </Col>
              </Row>
            </Container>
            <div className="section py-0">
              <Container>
                <Row>
                  {data.allContentfulNews.edges.map(news => (
                    <NewsCard news={news} key={news.node.id} />
                  ))}
                </Row>
              </Container>
            </div>
            <Container>
              <Row className="justify-content-center">
                <Col md="auto">
                  <Pagination pageInfo={pageInfo} />
                </Col>
              </Row>
            </Container>
          </div>
        </Layout>
      )}
    </I18n>
  )
}
export const query = graphql`
  query NewsListQuery($lng: String!, $skip: Int, $limit: Int) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
    allContentfulNews(
      filter: { node_locale: { eq: $lng } }
      sort: { fields: publishedAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          slug
          title
          shortDescription
          publishedAt(locale: $lng)
          cover {
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
    }
  }
`

export default withI18next()(NewsList)
